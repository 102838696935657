.brand-display{
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    display: flex;
    padding: 5%;
    padding-top: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.product{
    box-sizing: border-box;
    width: 70%;
    height: 270px;
    background-color: rgb(245, 245, 245);
    margin: 10px;
    padding: 1%;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px 2px #5098db;
    overflow-wrap: break-word;
    transition: .3s;
}
.product:hover{
    box-shadow: 0px 0px 10px 4px #5098db;
}
.pro-img{
    width: 90%;
    height: 160px;
    border-radius: 10px;
    margin: 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
}

.pro-title{
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 20%;
}

.cart-button{
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 5%;
}
.pro-title{
    font-size: 20px;
}
@media (min-width: 768px){
    .product{
        height: 400px;
    }
    .pro-img{
        height: 260px;
    }
    #display-title{
        font-size: 27px;
        bottom: 15%;
    }
    
}
@media (min-width: 1024px){
    #brand-com{
        padding-top: 10%;
    }
    .product{
        height: 450px;
    }
    .pro-img{
        height: 300px;
    }
}
@media (min-width: 1280px){
    .product{
        height: 480px;
        width: 65%;
    }
    .pro-img{
        height: 330px;
        width: 70%;
    }
}
@media (min-width: 1440px){
    .product{
        width: 48%;
    }
    #brand-com{
        padding-top: 7%;
    }
}