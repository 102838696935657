.slider{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.logo{
    width: 100%;
    height: 100%;
}
#ua{
    padding-top: 150px;
}
@media (min-width: 768px){
    .logo{
        width: 100%;
        height: 100%;
    }
    #ua{
        margin-top: 60px;
    }
    .align-slide{
        width: 80%;
        height: 100%;
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}
@media (min-width: 1024px){
    .align-slide{
        width: 65%;
    }
    #ua{
        margin-top: 70px;
    }
}
@media (min-width: 1280px){
    .align-slide{
        top: -50px;
        width: 45%;
    }   
}
@media (min-width: 1440px){
    .align-slide{
        top: -60px;
    }
}
@media (min-width: 1700px){
    .align-slide{
        width: 50%;
        top: -450px;
    }   
    #ua{
        margin-top: 225px;
    }
}