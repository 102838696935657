.account{
    width: 100vw;
    height: 85.2vh;
    display: flex;
    justify-content: center;
}
.user-info{
    color: #5098db;
}
.form button{
    width: 130px;
    margin: 5px;
    padding: 10px;
    font-size: 16px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.form button:hover{
    background-color: black;
}
@media (min-width: 768px){
.form button{
font-size: 25px;
width: 150px;
}
.account{
 box-sizing: border-box;
 height: 91vh;
}
#account-button{
    width: 300px;
}
.user-info{
    font-size: 25px;
}
}
@media (min-width: 1440px){
 .form button{
     font-size: 30px;
 }   
}
