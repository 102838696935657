.navbar{
box-sizing: border-box;
width: 100%;
height: 7.3vh;
padding: 5px;
display: flex;
justify-content: center;
align-items: center;
background-color: whitesmoke;
border-bottom: 2px solid #5098db;
position: relative;
z-index: 100;
/* overflow-x: hidden; */
/* box-shadow: 1px 1px 5px 1px grey; */
}

.brands{
display: flex;
justify-content: space-evenly;
flex-direction: row;
width: 67%;
height: 100%;
margin-top: 11px;
/* background-color: cornflowerblue; */
}

.user-nav{
position: absolute;
right: 2%;
}

.title{
width: 150px;
height: 110px;
/* background-color: grey; */
}

.title h1{
    transition: .2s;
    font-size: 12px;
    padding-bottom: 13px;
    color: #5098db;
    font-weight: bolder;
}

.title p{
    display: none;
    font-size: 16px;
    text-decoration: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #5098db;
}
.title:hover p{
    display: flex;
}
.title p:hover{
    color: white;
}
.title:hover h1{
    border-bottom: solid black 6px;
    color: black;
}
.nav:hover, .nav:visited, .nav:link, .nav:active{
    text-decoration: none;
}
.home-icon{
position: absolute;
left: 2%;
/* top: 10.5px; */
top: 0;
bottom: 0;
margin-top: auto;
margin-bottom: auto;
font-size: 30px;
color: #5098db;
transition: .5s;
}
.home-icon:hover{
color: black;
}
.shopping-cart{
    font-size: 27px;
    margin-right: 5px;
    color: #5098db;
}
.shopping-cart:hover{
    color: black;
}
.user-circle{
    font-size: 27px;
    color: #5098db;
}
.user-circle:hover{
    color: black;
}
.link{
    text-decoration: none;
}
@media (max-width: 375px){
    .title h1{
        font-size: 10px;
    }
}
@media (min-width: 768px){
    .navbar{
        height: 9vh;
    }
    .title{
        height: 165px;
    }
    .title h1{
        font-size: 20px;
        padding-bottom: 25px;
    }
    .title p{
        font-size: 22px;
    }
    .title:hover h1{
        border-bottom: solid black 8px;
        color: black;
    }
    .home-icon{
        font-size: 45px;
    }
    .shopping-cart{
        font-size: 37px;
        margin-right: 12px;
    }
    .user-circle{
        font-size: 37px;
    }
    .brands{
        margin-top: 32px;
    }
}
@media (min-width: 1024px){
    .brands{
        margin-top: 46px;
    }
    .title h1{
        padding-bottom: 20px;
    }
}
@media (min-width: 1280px){
    .brands{
        margin-top: 1px;
    }
}
@media (min-width: 1440px){
    .brands{
        margin-top: 0px;
    }
    .title h1{
        font-size: 22px;
        padding-bottom: 18px;
    }
}
@media (min-width: 1700px){
 .title h1{
     font-size: 22px;
     padding-bottom: 23.5px;
 }   
 .brands{
     margin-top: 20px;
 }
 .shopping-cart{
     font-size: 42px;
     margin-right: 15px;
 }
 .user-circle{
     font-size: 42px;
 }
 .home-icon{
     font-size: 47px;
 }
}