.product-view{
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    position: relative;
}
.product-display{
    width: 80%;
    height: 100%;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 2px #5098db;
    overflow-wrap: break-word;
    padding: 10px;
    box-sizing: border-box;
}
.product-img{
    width: 85%;
    height: 200px;
    margin: 10px;
}
.product-display p{
    font-size: 20px;
}
.back-arrow{
    font-size: 30px;
    position: absolute;
    left: 45px;
    top: 35px;
    color:#5098db;
}
.back-arrow:hover{
    color: white;
}
.title-align{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 2px solid black;
}
.product-description{
    margin: 5px;
    text-align: left;
}
@media (min-width: 768px){
    .back-arrow{
        font-size: 35px;
        left: 80px;
        top: 40px;
    }
    .product-view{
        padding-top: 120px;
    }
    .product-display p{
        font-size: 25px;
    }
    .product-img{
        height: 310px;
    }
}
@media (min-width: 1024px){
    .product-img{
        height: 420px;
    }
    .back-arrow{
        left: 105px;
        top: 45px;
    }
}
@media (min-width: 1280px){
    .product-img{
        height: 330px;
        width: 80%;
    }
    .product-display{
        width: 65%;
    }
    .back-arrow{
        left: 235px;
    }
}
@media (min-width: 1440px){
    .product-display{
        width: 60%;
    }
    .back-arrow{
        left: 300px;
    }
}
@media (min-width: 1700px){
    .product-display{
        width: 55%;
    }
    .product-img{
        height: 540px;
    }
    .back-arrow{
        left: 440px;
    }
}