.landing{
    box-sizing: border-box;
    padding: 10px;
    width: 100vw;
    height: 80vh;
    overflow: hidden;
    opacity: 1;
    transition: 2s;
}
.op-landing{
    box-sizing: border-box;
    padding: 10px;
    width: 100vw;
    height: 80vh;
    /* height: 92.7vh; */
    opacity: 0;
}
.search-box{
    position: absolute;
    top: 225px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: blue;
    z-index: 100;
    background: white;
    display: flex;
    border-radius: 50px;
    transition: .5s;
    padding: 10px;
    box-shadow: 0px 0px 10px 8px #5098db;
}
/* .search-box:hover .search-text{
    width: 220px;
    padding: 0 6px;
} */
.search-box .search-button:hover{
 background: #5098db;
 color: black;
}
.search-button{
    color: #5098db;
    float: right;
    /* width: 100px; */
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    border-radius: 50px;
    background: black;
    transition: .5;
}
@media (min-width: 768px){
    .landing{
        height: 91vh;
        display: flex;
        justify-content: center;
    }
    .search-button{
        height: 65px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 35px;
    }
    .search-box{
        top: 275px;
    }
}
@media (min-width: 1440px){
    .search-box{
        top: 285px;
    }
}
@media (min-width: 1700px){
    .search-box{
        top: 300px;
    }
} 
