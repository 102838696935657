.auth{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100vw;
    height: 92.7vh;
}
.form{
    margin-top: 12vh;
    width: 65%;
    height: 50vh;
    padding: 10px;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px #5098db;
    position: relative;
}
.form input{
    width: 90%;
    font-size: 20px;
    padding: 5px;
    border: 1px #5098db solid
}
.form p{
    font-size: 20px;
    color: #5098db;
}
.form span{
    font-size: 20px;
}
.form-button{
    width: 120px;
    padding: 15px;
    font-size: 20px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.check-text{
    position: absolute;
    top: 2px;
    left: 14px;
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
}
.check-text input{
    width: 20px;
    height: 10px;
}
.admin-text{
    position: absolute;
    color: white;
}
@media (min-width: 768px){
    .auth{
        height: 91vh;
    }
    .form{
        width: 62vw;
    }
    .form input{
        padding: 10px;
        box-sizing: border-box;
    }
    .form-button{
        font-size: 25px;
    }
    .form span{
        font-size: 25px;
    }
    .form p{
        font-size: 25px;
    }
    .admin-text{
        font-size: 18px;
    }
    .check-text{
        left: 37px;
    }
    .check-text input{
        height: 15px;
        width: 15px;
    }
}
@media (min-width: 1024px){
    .form{
        padding-top: 25px;
    }
}
@media (min-width: 1280px){
    .check-text{
        left: 55px;
        align-items: flex-end;
    }
    .check-text input{
        width: 100px;
        height: 20px;
    }
}
@media (min-width: 1440px){
    .form{
        width: 55%;
    }
    .admin-text{
        font-size: 22px;
    }
}
@media (min-width: 1700px){
    .form{
        width: 48%;
        height: 45vh;
    }
    .form p{
        font-size: 30px;
    }
    .form span{
        font-size: 30px;
    }
    .form-button{
        font-size: 30px;
    }
    .check-text{
        width: 100px;
        left: 62px;
    }
    .check-text span{
        font-size: 25px;
    }
    .check-text input{
        /* width: 100px; */
        height: 20px;
    }
}