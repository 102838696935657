.payment-card{
    box-sizing: border-box;
    position: absolute;
    width: 75%;
    background-color: whitesmoke;
    height: 300px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    top: 130px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0px 0px 5px 2px #5098db;
    border-radius: 5px;
}
.payment-card input{
    border: 1px solid #5098db;
    padding: 5px;
}
.card-element{
    border: 1px solid #5098db;
    padding: 7px;
}
.payment-button{
    padding: 10px;
    border: 2px solid #5098db;
    background-color: white;
    font-weight: bolder;
}
.payment-button:hover{
    background-color: black;
    color: white;
}
.test-card{
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
}
@media (min-width: 768px){
    .payment-card{
        top: 210px;
        width: 62vw;
    }
    .payment-card input{
        padding: 7px;
        font-size: 17px;
    }
    .payment-button{
        font-size: 20px;
    }
    .test-card{
        font-size: 17px;
    }
}
@media (min-width: 1280px){
    .payment-card{
        width: 55vw;
    }
}
@media (min-width: 1700px){
    .payment-card{
        width: 45vw;
    }   
}