.select-edit{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
}
.select-product{
    box-sizing: border-box;
    width: 70%;
    height: 270px;
    background-color: rgb(245, 245, 245);
    margin: 10px;
    padding: 1%;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px 2px #5098db;
    overflow-wrap: break-word;
    top: 150px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.select-img{
    position: absolute;
    top: 1%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 230px;
    height: 175px;
}
.select-product p{
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;    
    font-weight: bolder;
}
.select-button{
    position: absolute;
    bottom: 10px;
    box-sizing: border-box;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 40px;
    font-size: 20px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.select-button:hover{
    background-color: black;
}
.select-align{
    position: absolute;
    top: 135px;
}
.select-align input{
box-sizing: border-box;
margin-right: 5px;
width: 220px;
padding: 10px;
font-size: 15px;
height: 40px;
border: 2px solid #5098db;
}
.select-align button{
    box-sizing: border-box;
    height: 40px;
    width: 70px;
    background-color: white;
    color: black;
    border: 2px solid #5098db;
    font-weight: bolder;
}
.select-align button:hover{
    background-color: black;
    color: white;
}
@media (min-width: 768px){
    .select-align{
        top: 195px;
    }
    .select-edit input{
        width: 310px;
        padding: 15px;
        height: 50px;
        font-size: 17px;
        margin-right: 10px;
    }
    .select-edit button{
        height: 50px;
        width: 80px;
        font-size: 17px;
    }
    .select-product{
        height: 370px;
        top: 160px;
    }
    .select-product img{
        height: 250px;
        width: 320px;
    }
    .select-product p{
        font-size: 20px;
        bottom: 55px;
    }
    .select-product button{
        width: 120px;
        height: 50px;
    }
}
@media (min-width: 1024px){
    .select-product{
        top: 175px;
    }
    .select-product p{
        font-size: 25px;
    }
    .select-product button{
        font-size: 25px;
    }
}
@media (min-width: 1280px){
    .select-product{
        width: 55%;
    }
}
@media (min-width: 1700px){
    .select-product{
        width: 48%;
        height: 400px;
    }
    .select-product img{
        height: 260px;
        width: 360px;
    }
}
