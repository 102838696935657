.pro-search-box{
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: blue;
    z-index: 100;
    background: white;
    display: flex;
    border-radius: 50px;
    transition: 2s;
    padding: 10px;
    box-shadow: 0px 0px 5px 3px #5098db;
}
.pro-mount{
    position: absolute;
    top: 225px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: blue;
    z-index: 100;
    background: white;
    display: flex;
    border-radius: 50px;
    transition: 2s;
    padding: 10px;
    box-shadow: 0px 0px 10px 8px #5098db;
}
.pro-search-button:hover{
 background: #5098db;
 color: black;
}
.pro-search-button{
    color: #5098db;
    float: right;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    border-radius: 50px;
    background: black;
    transition: .5;
}
.pro-search-text{
    border: none;
    background: none;
    width: 220px;
    outline: none;
    float: left;
    padding: 0 6px;
    color: black;
    font-size: 16px;
    transition: 1.5s;
}
.no-text{
    border: none;
    background: none;
    width: 0px;
    outline: none;
    float: left;
    padding: 0;
    color: black;
    font-size: 16px;
    transition: 1.5s;
}

.search-product{
    box-sizing: border-box;
    width: 70%;
    height: 270px;
    background-color: rgb(245, 245, 245);
    margin: 15px;
    padding: 1%;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px 2px #5098db;
    overflow-wrap: break-word;
    top: 170px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 120;
    transition: .3s;
}
.search-product:hover{
    box-shadow: 0px 0px 10px 4px #5098db;
}
.search-pro-img{
    width: 90%;
    height: 160px;
    border-radius: 10px;
    margin: 5px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
}
.search-pro-title{
    font-size: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 20%;
    font-weight: bolder;
    
}
.search-cart-button{
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 5%;
    padding: 15px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.search-cart-button:hover{
    background-color: black;
}
.no-pro{
    color: white;
    font-size: 20px;
}
@media (min-width: 768px){
    .pro-search-button{
        height: 65px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 35px;
    }
    .pro-search-text{
        width: 270px;
        font-size: 20px;
    }
    .pro-search-box{
        top: 245px;
    }
    .pro-mount{
        top: 275px;
    }
    .search-product{
        top: 205px;
        height: 370px;
    }
    .search-pro-img{
        height: 260px;
    }
    .search-pro-title{
        bottom: 15%;
    }
}
@media (min-width: 1280px){
    .search-product{
        top: 210px;
        width: 50%;
        height: 400px;
    }
    .search-pro-img{
        height: 280px;
        width: 85%;
    }
    .search-cart-button{
        font-size: 19px;
    }
}
@media (min-width: 1440px){
    .pro-search-text{
        width: 300px;
    }
    .pro-mount{
        top: 285px;
    }
}
@media (min-width: 1700px){
    .pro-search-text{
        width: 320px;
    }
    .pro-mount{
        top: 300px;
    }
    .pro-search-box{
        top: 255px;
    }
    .search-product{
        width: 42%;
        height: 480px;
    }
    .search-pro-img{
        height: 320px;
        width: 75%;
    }
}
