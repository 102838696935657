.add-product{
    box-sizing: border-box;
    width: 100vw;
    height: 92.7vh;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    position: relative;
}
.add-form{
    box-sizing: border-box;
    border-radius: 5px;
    width: 83%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    padding: 10px;
    box-shadow: 0px 0px 5px 2px #5098db;
}
.add-form input{
    width: 90%;
    height: 25px;
    padding: 5px;
    margin-bottom: 10px;
    border-color: #5098db;
    border: 1px solid #5098db;
}
#de-input{
    vertical-align: text-top;
    height: 150px;
    width: 90%;
    padding: 5px;
    resize: none;
    border-color: #5098db;
    margin-bottom: 10px;
}
.forms-group{
    display: flex;
    margin-top: 10px;
}
.forms-group select{
    margin-bottom: 40px;
    border-color: #5098db;
}
.add-form button{
    padding: 15px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.add-form button:hover{
    background-color: black;
    color: white;
    border: 2px solid rgb(185, 185, 185);
}
.brand-select{
    margin-right: 7px;
}
.back-arrow-add{
    font-size: 30px;
    position: absolute;
    left: 32px;
    top: 30px;
    color:#5098db;
}
.back-arrow-add:hover{
    color: white;
}
@media (min-width: 768px){
    .add-product{
        padding-top: 110px;
    }
    .add-form{
        height: 650px;
    }
    .add-form button{
        font-size: 25px;
    }
    .add-form input{
        margin-bottom: 20px;
        height: 40px;
        font-size: 17px;

    }
    #de-input{
        margin-bottom: 20px;
        height: 185px;
        font-size: 17px;
    }
    .forms-group select{
        padding-right: 5px;
        font-size: 17px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 210px;
    }
    .forms-group label{
        font-size: 20px;
    }
    .brand-select{
        margin-right: 25px;
    }
    .back-arrow-add{
        font-size: 35px;
        left: 65px;
        top: 40px;
    }
}
@media (min-width: 1024px){
    .back-arrow-add{
        left: 95px;
    }
    .add-product{
        height: 91vh;
    }
}
@media (min-width: 1280px){
    .back-arrow-add{
        left: 125px;
    }
    .add-form{
        height: 620px;
    }
}
@media (min-width: 1440px){
    .add-form{
        width: 60%;
    }
    .back-arrow-add{
        left: 300px;
        top: 36px;
    }
}
@media (min-width: 1700px){
    .add-form{
        width: 52%;
    }
    .back-arrow-add{
        left: 470px;
        top: 37px;
    }
}
