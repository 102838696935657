.cart-pro-display{
    box-sizing: border-box;
    width: 100vw;
    height: 100%;
    display: flex;
    padding: 5%;
    padding-top: 18%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-product{
    box-sizing: border-box;
    width: 70%;
    height: 270px;
    background-color: rgb(245, 245, 245);
    margin: 10px;
    padding: 1%;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px 2px #5098db;
    overflow: contain;
}

.pro-img{
    width: 350px;
    height: 300px;
    border-radius: 10px;
    margin: 5px;
}

.pro-title{
    font-size: 25px;
}

.cart-button{
    width: 130px;
    margin: 5px;
    padding: 10px;
    font-size: 16px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.cart-button:hover{
background-color: black;
}
.empty-cart{
    color: white;
    font-size: 20px;
    font-weight: bolder;
}
.purchase-total{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 18%;
    height: 70px;
    position: absolute;
    top: 50px;
    /* background-color: grey; */
    right: 1px;
}
.cart-total{
    color: white;
    font-weight: bolder;
    font-size: 13px;
}
.purchase-total button{
    padding: 1px;
    box-sizing: border-box;
    height: 25px;
    width: 72px;
    font-size: 14px;
    background-color: white;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.purchase-total button:hover{
    background-color: black;
}
@media (min-width: 768px){
    .cart-product{
        height: 370px;
    }
    .pro-title{
        font-size: 30px;
    }
    #cart-title{
        font-size: 25px;
        bottom: 50px;
        font-weight: bolder;
    }
    .cart-pro-display{
        padding-top: 12%;
    }
    .empty-cart{
        font-size: 25px;
    }
    .purchase-total{
        top: 92px;
        height: 92px;
        width: 120px;
        right: 10px;
    }
    .purchase-total button{
        width: 110px;
        height: 35px;
        font-size: 17px;
    }
    .cart-total{
        font-size: 17px;
    }
}
@media (min-width: 1024px){

    .cart-product{
        height: 440px;
    }
    #cart-title{
        bottom: 60px;
    }
    .cart-button{
        font-size: 20px;
        width: 180px;
    }
    .cart-pro-display{
        padding-top: 10%;
    }
    .purchase-total{
        right: 30px;
        height: 105px;
    }
    .cart-total{
        font-size: 19px;
    }
}
@media (min-width: 1280px){
    .cart-product{
        width: 60%;
        height: 500px;
    }
    .cart-pro-display{
        padding-top: 8%;
    }
    .purchase-total{
        right: 100px;
        top: 90px;
    }
    .cart-total{
        font-size: 20px;
    }
    .purchase-total{
        top: 75px;
        right: 90px;
    }
    .purchase-total button{
        font-size: 18px;
        height: 40px;
    }
}
@media (min-width: 1440px){
    .purchase-total{
        /* background-color: grey; */
        flex-direction: row;
        width: 235px;
        top: 90px;
        right: 52px;
    }
    .purchase-total button{
        font-size: 20px;
        width: 150px;
    }
}
@media (min-width: 1700px){
    .cart-product{
        width: 58%;
        height: 520px;
    }
    .cart-pro-display{
        padding-top: 6%;
    }
    #pro-cart-img{
        width: 60%;
        height: 360px;
    }
    .purchase-total{
        top: 100px;
        right: 65px;
        flex-direction: row;
        justify-content: space-evenly;
        width: 320px;
        /* background-color: grey; */
    }
    .cart-total{
        font-size: 24px;
    }
    .purchase-total button{
        font-size: 22px;
        width: 150px;
        height: 55px;
    }
}