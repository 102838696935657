
.edit-form{
    background-color: whitesmoke;
    width: 80%;
    height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    box-sizing: border-box;
    top: 100px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 5px 2px #5098db;
    border-radius: 5px;
}
.edit-form span{
    width: 90%;
    display: flex;
    justify-content: center;
}
.edit-form input{
    width: 75%;
    height: 30px;
    padding: 5px;
    border-color: #5098db;
    border: 1px solid #5098db;
    background-color: white;
}
.edit-align select{
    width: 122px;
    padding: 5px;
    border-color: #5098db;
    border: 1px solid #5098db;
}
.confirm-button{
    padding: 10px;
    font-size: 20px;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
}
.confirm-button:hover{
    background-color: black;
}
.delete-button{
    position: absolute;
    font-size: 15px;
    color: #5098db;
    font-weight: bolder;
    border: 2px solid #5098db;
    top: 10px;
    right: 10px;
}
.delete-button:hover{
    background-color: black;
}
.back-arrow-edit{
    font-size: 25px;
    position: absolute;
    left: 40px;
    top: 105px;
    color:#5098db;
}
.back-arrow-edit:hover{
    color: white;
}
@media (min-width: 768px){
    .edit-form{
        height: 600px;
        top: 115px;
    }
    .back-arrow-edit{
        font-size: 35px;
        left: 75px;
        top: 130px;
    }
    .edit-form input{
        font-size: 17px;
        padding: 10px;
    }
    .delete-button{
        font-size: 25px;
        top: 15px;
        right: 15px;
    }
    .edit-align select{
        font-size: 15px;
        width: 175px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .confirm-button{
        font-size: 25px;
        padding: 15px;
    }
}
@media (min-width: 1024px){
    .back-arrow-edit{
        left: 105px;
        top: 125px;
    }
}
@media (min-width: 1280px){
    .back-arrow-edit{
        left: 140px;
        top: 110px;
    }
}
@media (min-width: 1440px){
    .edit-form{
        width: 60%;
    }
    .back-arrow-edit{
        left: 300px;
        top: 120px;
    }
}
@media (min-width: 1700px){
    .edit-form{
        width: 52%;
    }
    .back-arrow-edit{
        left: 470px;
        top: 130px;
    }
}